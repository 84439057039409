/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    object, func, shape, string,
} from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import processCss from '../../../../helpers/contentstack/generateValidCss';
import Panel2 from '../../Panel';
import openInNewTab from '../../../../helpers/contentstack/openInNewTab';
import { openPassportSideBar } from '../../../../../state/ducks/Passport/Passport-Action';
import { getFeatureFlags } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';
import VideoComponent from '../../../GraphqlComponents/GraphqlProductPage/Partials/ProductImageUI/VideoComponent';
import Copy from '../../Copy';
import LinkOrATag from '../../../GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';

const styles = (theme) => ({
    root: {
        boxSizing: 'border-box',
        display: 'block',
        height: 'auto',
        width: '100%',
        margin: '15px auto',
        textDecoration: 'none',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        color: theme?.palette?.common?.black, // TODO: Font color should come from CMS
    },
    headline: {
        margin: `0 auto ${theme.spacing(3)}px auto`,
        padding: '10x 20px',
        textAlign: 'center',
        fontSize: '34px',
        color: theme?.palette?.common?.black, // TODO: Font color should come from CMS
    },
    headlineVideo: {
        textAlign: 'center',
        position: 'absolute',
        margin: '0 auto',
        top: '50%',
        right: '50%',
        transform: 'translate(50%, -50%)',
    },
    ctaLink: {
        textDecoration: 'none',
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    contentSection: {
        margin: '0 auto',
        textAlign: 'center',
    },
    contentSectionVideo: {
        width: '100%',
        height: 'auto',
        margin: '0 auto',
    },
    float: {
        marginLeft: '0px',
    },
    mobileRoot: {
        display: 'block',
        margin: '15px auto',
    },
    bannerImage: {
        width: '100%',
        height: 'auto',
    },
    contentSec: {
        position: 'absolute',
        top: 0,
        textAlign: 'center',
        width: '100%',
    },
    contentInnerHeading: {
        width: '70%',
        fontFamily: 'loretta-variable, PlayfairDisplayBold, serif',
        color: theme.palette.cms?.heading || '#000000',
        '& h1, h2': {
            margin: 0,
        },
    },
    contentInnerText: {
        width: '70%',
        fontFamily: 'Area Variable, LatoBold, sans-serif',
        color: theme.palette.cms?.black || '#000000',
        '& h1, h2, p': {
            margin: 0,
        },
    },
    productVideo: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        '&::-webkit-media-controls-play-button': {
            display: 'flex !important',
        },
    },
});

const GenericBannerMobile = ({
    classes, data, trackEvent, passportOpenSideBar, featureFlags, ssrDeviceType, salesforceResponse, setShowSkeleton,
}) => {
    const banner = data.reference[0];
    if (!banner) return null;
    const isMobile = ssrDeviceType.toLowerCase() === 'mobile';

    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse,
        isFireImpression: !!salesforceResponse?.content,
        page: { type: 'home' },
    });

    const handleClick = (e, panel) => {
        if (panel.linking?.link?.title === 'Celebrations Passport'
            // VARIANT A is "Passport Enrolment Page"
            // CONTROL is "Passport Side Bar"
            && featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'CONTROL') {
            e.preventDefault();
            passportOpenSideBar();
        }
        trackEvent({
            eventCategory: panel.linking?.tracking_event_category || '',
            eventAction: panel.linking?.tracking_event_action || '',
            eventLabel: panel.linking?.tracking_event_label || '',
        });
        trackClickThrough();
    };

    if (!banner.reference?.[0]?.image_mobile || !banner.reference?.[0]?.image) {
        const panel = banner.reference?.[0];
        const panelData = {
            message_collection: panel?.message_collection,
            isMobileCopy: panel?.is_mobile_copy || false,
        };
        if (!banner || !panel) {
            return null;
        }

        const unit = banner.dimensions?.unit === 'Percent' ? '%' : 'px';

        const useNewDimensionsIfAvailable = () => {
        /* @intention: To ease the transition of updates made to the CMS
                *             content-type. New fields were added to handle
                *             descrete values and in order to preserve existing
                *             entries (and eliminate broken presentation), the
                *             old fields were "hidden" but the vaules will remain.
                * @return: {obj} - new field values || old field values.
                */
            const obj = {
                width: `${banner.dimensions?.width}${unit}`,
                height: `${banner.dimensions?.height}${unit}`,
                margin: '0 auto',
            };
            if (banner.dimensions?.width_x) {
                obj.width = banner.dimensions.width_x;
            }
            if (banner.dimensions?.height_y && !isMobile) {
                obj.height = banner.dimensions.height_y;
            }
            if (banner.dimensions?.mobile_height && isMobile) {
                obj.height = banner.dimensions.mobile_height;
            }
            if (banner.dimensions?.max_width) {
                obj.maxWidth = banner.dimensions?.max_width;
            }
            return obj;
        };
        const bannerStyle = useNewDimensionsIfAvailable();
        bannerStyle.backgroundImage = panel?.image_mobile?.url ? `url(${panel?.image_mobile?.url}?auto=webp)` : '';

        return (
            <>
                {panel?.banner_clickable ? (
                    <LinkOrATag
                        isTrackEventEnabled
                        trackingEventCategory={panel?.linking?.tracking_event_category || ''}
                        trackingEventAction={panel?.linking?.tracking_event_action || ''}
                        trackingEventLabel={panel?.linking?.tracking_event_label || ''}
                        href={panel.linking?.link?.href}
                        title={panel.linking?.link?.title}
                        style={bannerStyle}
                        className={classes.root}
                    >
                        <div className={classes.contentSection} style={processCss(panel.container_attributes, true)}>
                            {panelData.isMobileCopy && (
                                <Panel2 className={classes.headline} data={panelData} styleData={panel?.message_styling} />
                            )}
                        </div>
                    </LinkOrATag>
                ) : (
                    <div className={classes.contentSection} style={processCss(panel.container_attributes, true)}>
                        <Panel2 className={classes.headline} data={panelData} styleData={panel?.message_styling} />
                    </div>
                )}
            </>
        );
    }
    const location = useLocation();
    const isLocalGiftsPage = location?.pathname === '/wanderers-lp' || location?.pathname === '/local-gifts';
    if (banner.reference?.[0]?.image_mobile) {
        const panel = banner.reference?.[0];
        const panelData = {
            message_collection: panel?.message_collection,
            isMobileCopy: panel?.is_mobile_copy,
        };

        const getMobileTelValue = panel?.linking?.link?.href?.includes('tel:') || panel?.linking?.link?.href?.includes('#disclaimer');
        const isMobileCopy = banner.reference?.[0].is_mobile_copy || false;

        const mobileBannerImageRender = () => {
            if (panel?.image_mobile?.content_type === 'video/mp4') {
                return (
                    <div className={classes.contentSectionVideo} style={{ ...processCss(panel.container_attributes, true) }}>
                        <VideoComponent
                            isBRY={!!isLocalGiftsPage}
                            isMuted={!!isLocalGiftsPage}
                            classes={classes}
                            videoUrl={panel?.image_mobile?.url || ''}
                            videoClass={classes.productVideo}
                            contentType={panel?.image_mobile?.content_type || ''}
                        />
                        <div className={classes.headlineVideo}>
                            <Panel2 className={classes.headline} data={panelData} styleData={panel?.message_styling} />
                        </div>
                    </div>
                );
            }

            return (
                <>
                    <img className={classes.bannerImage} src={panel?.image_mobile?.url} alt={panel.linking?.link?.title} />
                    {isMobileCopy && (
                        <div className={classes.contentSec}>
                            <Copy data={panelData?.message_collection} />
                        </div>
                    )}
                </>
            );
        };

        const mobileClickableBanner = () => (
            getMobileTelValue ? (
                <a
                    data-ga-category={panel.linking?.tracking_event_category || ''}
                    data-ga-action={panel.linking?.tracking_event_action || ''}
                    data-ga-label={panel.linking?.tracking_event_label || ''}
                    onClick={(e) => {
                        handleClick(e, panel);
                    }}
                    tabIndex="0"
                    href={panel.linking?.link?.href}
                    title={panel.linking?.link?.title}
                    className={classes.mobileRoot}
                >
                    {mobileBannerImageRender()}
                </a>
            ) : (
                <Link
                    data-ga-category={panel.linking?.tracking_event_category || ''}
                    data-ga-action={panel.linking?.tracking_event_action || ''}
                    data-ga-label={panel.linking?.tracking_event_label || ''}
                    onClick={(e) => {
                        handleClick(e, panel);
                    }}
                    tabIndex="0"
                    to={{ pathname: panel?.linking?.link?.href }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...openInNewTab(panel.linking?.link?.href)}
                    title={panel.linking?.link?.title}
                    className={classes.mobileRoot}
                >
                    {mobileBannerImageRender()}
                </Link>
            )
        );

        return (
            panel?.banner_clickable ? (
                mobileClickableBanner()
            ) : (
                mobileBannerImageRender()
            )

        );
    }

    return <></>;
};

GenericBannerMobile.propTypes = {
    data: object.isRequired,
    classes: object.isRequired,
    trackEvent: func.isRequired,
    featureFlags: shape({
        'which-passport-sidebar-enabled-variant': shape({
            variant: string,
        }),
    }),
    passportOpenSideBar: func,
    ssrDeviceType: string,
    salesforceResponse: object,
    setShowSkeleton: func.isRequired,
};

GenericBannerMobile.defaultProps = {
    featureFlags: {},
    passportOpenSideBar: () => { },
    ssrDeviceType: 'desktop',
    salesforceResponse: {},
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    ssrDeviceType: getSSRDeviceType(state),
});
const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(GenericBannerMobile));
